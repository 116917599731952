/* @import url("https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap");

body {
  color: #fff;
  font-family: "Roboto Condensed", sans-serif;
  background-image: url(https://images.unsplash.com/photo-1508389377389-b8221c0bcc9e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80);
  background-position: top top;
  background-size: cover;
  margin: 0;
} */

.App {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  background-image: linear-gradient(to right top, #d9fefe, #cffaf9, #d5f7f7, #f4eb89, #f5eb75);
}

.container {
  width: 100%;
  margin: 15% auto;
}
.col-4 {
  width: 25%;
  float: left;
}

h1 {
  font-size: 38px;
  text-transform: uppercase;
  line-height: 1;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

h2{
  font-size: 32px;
  text-transform: uppercase;
  line-height: 1;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  margin-bottom: 20px;
}

@media screen and (max-width: 800px) {
  .container {
    padding-top: 55px;
  }
  h1 {
    font-size: 33px;
  }
  h2 {
    font-size: 26px;
  }
}
