.optin {
  margin-top: 3%;
}
.optin p {
  font-size: 14px;
  font-weight: 100;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.cardIcon{
  margin: 12.5px;
  font-size: 25px;
  color: rgb(227, 100, 54)
}

@media screen and (max-width: 800px) {
  .optin p {
    padding-top: 15px;
    font-size: 12px;
  }
  .cardIcon {
    margin-top: 15px;
    font-size: 22.5px;
  }
}
